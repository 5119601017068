
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Icon, Spin, Tabs } from 'ant-design-vue';
import { getProjectTabsConfiguration, onKeyChange } from '@/helpers/NewProject/NewProjectHelper';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Project from '@/models/Project';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ProjectContent',
    components: { Tabs, TabPane: Tabs.TabPane, Spin, Icon },
    inject: [],
})
export default class ProjectContent extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ required: true }) private canSeeOrders!: boolean;
    @Prop({ required: false }) private basicProjectData!: any;
    @Prop({ required: false }) private isCurrentUserTechnologist!: boolean;
    @Prop({ required: true }) private selectedOffer!: any;
    @Prop({ required: false }) private createCustomOrder!: boolean;
    @Prop({ required: true }) private lockEditing!: boolean;
    @Prop({ required: true }) private isProjectFetching!: boolean;

    private projectTabs: string[] = [];
    private activeKey: ProjectTabValues | null = null;
    private isTabLoading = false;
    private loadedKeys = new Set();

    private get isProjectLoading() {
        return this.isProjectFetching || this.isTabLoading;
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private async updateActiveKey(key: string | Array<string | null>) {
        try {
            await onKeyChange(this.$route, this.activeKey, key);
        } catch (e) {
            return;
        }

        this.activeKey = Number(key);
    }

    private get isRoltekUG() {
        return this.$store.getters['jwtData/isRoltekUserGroup'];
    }

    private get canSeeInquiries() {
        return this.userRights.includes(UserRightsEnum.SEE_INQUIRIES);
    }

    private get canSeePaymentTab() {
        return this.isRoltekUG;
    }

    private updateProjectTabs() {
        // @ts-ignore
        this.projectTabs = getProjectTabsConfiguration({
            canSeeInquiries: this.canSeeInquiries,
            canSeeOrders: this.canSeeOrders,
            isCurrentUserTechnologist: this.isCurrentUserTechnologist,
            canSeePaymentTab: this.canSeePaymentTab,
        });
    }

    private updateActiveKeyWithoutRoute() {
        this.activeKey = Number(this.$route.query.initialTab);
    }

    private async created() {
        this.updateProjectTabs();
        this.updateActiveKeyWithoutRoute();
    }

    @Watch('project.state')
    private async onProjectStateChange(state: string) {
        if (state !== 'order') {
            return;
        }

        this.updateProjectTabs();
    }
}
